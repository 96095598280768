import { Tag } from "antd";
import { decoderStatus, decoderStatusTrackTableColumnKeys } from "./constant";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";

export const decoderStatusTrackTableColumns = (handleOpenSheet, sheetName) => {
  const columns = [
    {
      title: "DATE",
      dataIndex: decoderStatusTrackTableColumnKeys.DATE,
      key: decoderStatusTrackTableColumnKeys.DATE,
      width: "20%",
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: "STATUS",
      dataIndex: decoderStatusTrackTableColumnKeys.STATUS,
      key: decoderStatusTrackTableColumnKeys.STATUS,
      width: "20%",
      render: (text) => <div>{statusTag(decoderStatus, text)}</div>,
    },
    {
      title: "SOURCE",
      dataIndex: decoderStatusTrackTableColumnKeys.SOURCE,
      key: decoderStatusTrackTableColumnKeys.SOURCE,
      width: "20%",
      render: (text, record) => (
        <div className="policies-cell">
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      ),
    },
    {
      title: "DOCUMENT",
      dataIndex: decoderStatusTrackTableColumnKeys.DOCUMENT,
      key: decoderStatusTrackTableColumnKeys.DOCUMENT,
      width: "20%",
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: "ACTION",
      dataIndex: decoderStatusTrackTableColumnKeys.ACTION,
      key: decoderStatusTrackTableColumnKeys.ACTION,
      width: "20%",
      render: (text, record) => (
        <button
          className="primary-button-border button"
          onClick={() => handleOpenSheet(sheetName, record.date)}
        >
          {text}
        </button>
      ),
    },
  ];

  return columns;
};

export const getButtonSelectEngagement = (
  nextStep,
  backStep,
  navigate,
  state
) => {
  const buttonGroupSelectClient = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: () => navigate("/"),
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        className: "primary-button",
        fn: () => nextStep(state.step, "trackSheetStatus"),
        disabled: state.cikNumber?.client?.cikNumber ? false : true,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };
  return buttonGroupSelectClient;
};

export const statusTag = (statusArray, status) => {
  const statusItem = statusArray.find((s) => s.value === status);
  if (!statusItem) return null;
  return (
    <Tag
      style={{
        ...statusItem.style,
      }}
    >
      <div className="status-chip">
        <div
          className="status-dot"
          style={{ backgroundColor: statusItem.style.dotColor }}
        ></div>
        {statusItem.status}
      </div>
    </Tag>
  );
};
