import { useEffect, useState } from "react";
import images from "../../components/images/images";
import { Radio, Skeleton } from "antd";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import Aries from "../../locale/en-Us.json";

const RadioGroup = Radio.Group;

export const DecoderSelectSheetOption = ({
  label,
  backStep,
  nextStep,
  sheetName,
  checkedSheet,
  handleSelectSheet,
  statementType
}) => {
  console.log(statementType, 'statementType')
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const onChange = (e) => {
    const selected = e.target.value;
    setSelectedValue(selected);
    setSelectedIndex(sheetName.indexOf(selected));
    handleSelectSheet([selected], selectedIndex);
  };

  useEffect(() => {
    if (checkedSheet?.length > 0) {
      setSelectedValue(checkedSheet[0]);
      setSelectedIndex(sheetName.indexOf(checkedSheet[0]));
    } else {
      setSelectedValue(null);
      setSelectedIndex(null);
    }
  }, [checkedSheet, sheetName]);

  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        className: "primary-button",
        fn: nextStep,
        disabled: selectedValue !== null ? false : true,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  return (
    <div className="decoder-sheet-container-display">
      <div className="wrapper-label">
        <img
          className="user-icon"
          src={images["aistra-vaa-avatar-1.svg"]}
          loading="lazy"
          alt="User icon"
        />
        <div
          className="label"
          dangerouslySetInnerHTML={{ __html: label.replace(/{sheet_name}/g, statementType), }}
        ></div>
      </div>
      {sheetName?.length !== 0 ? (
        <RadioGroup
          className="multi-options-box"
          options={sheetName}
          value={selectedValue}
          onChange={onChange}
        />
      ) : (
        <div className="multi-options-box-loading">
          <Skeleton active />
        </div>
      )}

      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </div>
  );
};
