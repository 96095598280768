import { useState } from "react";
import {
  getSheetNameApi,
  getSheetGuidanceApi,
  getSheetGuidanceSubmitApi,
  getGuidanceRequiredApi,
  getuploadedSheetFeildApi,
} from "./api";
import { useDispatch } from "react-redux";

export const useGetSheetName = () => {
  const dispatch = useDispatch();
  const [sheetName, setSheetname] = useState([]);
  const getSheetName = async (file_id) => {
    const data = await getSheetNameApi(file_id, dispatch);
    return data;
  };
  return { getSheetName };
};

export const useGetSheetGuidance = () => {
  const dispatch = useDispatch();
  const [sheetGuidance, setSheetGuidance] = useState({});

  const getSheetguidance = async (sheetId, sheets) => {
    setSheetGuidance({});
    const data = await getSheetGuidanceApi(sheetId, sheets, dispatch);
    setSheetGuidance(data);
  };

  return { getSheetguidance, sheetGuidance, setSheetGuidance };
};

export const useGetSheetGuidanceSubmit = () => {
  const dispatch = useDispatch();
  const [submitedSheetReponse, setSubmitedSheetReponse] = useState({});
  const submitSheetguidance = async (sheetId, sheets) => {
    const data = await getSheetGuidanceSubmitApi(sheetId, sheets, dispatch);
    setSubmitedSheetReponse(data);
    return data;
  };

  return { submitedSheetReponse, submitSheetguidance };
};

// export const useGuidanceRequire = () => {
//   const dispatch = useDispatch();
//   const [guidanceRequired, setGuidanceRequired] = useState(false);
//   const getGuidanceRequired = async (project_id) => {
//     const data = await getGuidanceRequiredApi(project_id, dispatch);
//     setGuidanceRequired(data);
//   };
//   return { getGuidanceRequired, guidanceRequired };
// };

export const checkGuidanceRequire = async (
  project_id,
  period,
  statementType
) => {
  const data = await getGuidanceRequiredApi(project_id, period, statementType);
  if (data === true) {
    const feildData = await getuploadedSheetFeildApi(project_id, period);
    if (feildData) {
      const sheetname = await getSheetNameApi(feildData.id);
      return { feildData, sheetname };
    }
  }
  return false;
};
